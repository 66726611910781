import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';

import Masonry from 'react-masonry-css'
// import ReactMasonry from 'react-masonry-component';
import { Reveal, Tween } from 'react-gsap';

import styles from "./masonry-commercials.1.module.scss"
import styled from "styled-components"


const Section = styled.section`


`


const Container = styled.div`
position: relative;
&:hover {
  img {
    transform: scale(1.2);
  }
}
`

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  
  img {
    transform: scale(1.0);
    transition: all 3s ease-out;
    object-fit: cover;
    height:100%;
    width: 100%;
  }
  
  // img:hover {
  //   transform: scale(1.2);
  // }
`

const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 0, 0.4);
  background: rgb(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${Container}:hover & {
    opacity: 1;
    padding: 5px;
  }
  
    h2 {
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 300;
      text-align: center;
    }
` 

// const masonryOptions = {
//     transitionDuration: 0,
//     //columnWidth: '30%',
//     itemSelector: '.grid-item',
//     percentPosition: true
// };
 
// const imagesLoadedOptions = { background: '.my-bg-image-el' } 

const MasonryCommercials2 = () => {
    const context = React.useContext(I18nextContext);
    const language = context.language;
    
    const items = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {regex: "/(commercials)/" },
            # frontmatter: { language: { eq: "EN" } } 
          } 
          sort: {fields: frontmatter___weight, order: DESC}
        ) {
          edges {
            node {
              frontmatter {
                thumbnail {
                  publicURL
                }
                title
                language
              }
              fields {
                slug
                category
                pathReference
              }
            }
          }
        }
      }
    `)
    
    let posts = items.allMarkdownRemark.edges
    let postsFilteredByLanguage = posts.filter(post=>post.node.frontmatter.language.toLowerCase() == language)
     
      
    const images = postsFilteredByLanguage.map((edge, key)=> {
      
      let publicURL
      try {
        publicURL = edge.node.frontmatter.thumbnail.publicURL
      } catch(error) {
        publicURL = ""
      }
      
      return (
          <Container key={key}>
            <Link to={`/${edge.node.fields.category}/${edge.node.fields.pathReference}`}>
              <ImageWrapper >
                <img src={publicURL} width="100%" height="auto" />
              </ImageWrapper>
              <Title><h2>{edge.node.frontmatter.title}</h2></Title>
            </Link>
          </Container>
      )
    })

    return(
        <Section >
           
               <Masonry
                breakpointCols={{default: 3, 1025: 2, 575: 1}}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
                >
              
                {images}
              
            </Masonry>
            
        </Section>
    )
    
}


export default MasonryCommercials2