import React from "react"
import {
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"


import Masonry from "../components/masonry-commercials.1"


const CommercialsPage = () => {
    const {t} = useI18next();
    
    return (
        <Layout>
            <SEO title={t("COMMERCIALS")} />
            <Masonry />
        </Layout>
        
    )
    
}

export default CommercialsPage;